<template>
  <div class="ad-home">
    <ad-header bg="banner2.jpg">
      <div class="banner-text-container">
        <div class="banner-text">
          <div class="en-title">
            <img src="../assets/images/banner-text2.png" />
          </div>
          <div class="line">
            <img src="../assets/images/pic_rectangular.png" />
          </div>
          <p>链接优秀人才，高提成零风险做单</p>
        </div>
        <div class="banner-login">
          <ad-login></ad-login>
        </div>
      </div>
    </ad-header>

    <ad-navs type="lt"></ad-navs>

    <div class="advantage-container">
      <div class="title">
        <div class="en-title">
          <img src="../assets/images/advantage 2.png" />
        </div>
        <div class="cn-title">我们的优势</div>
      </div>
      <div class="advantage-list">
        <div class="advantage-item">
          <img src="../assets/images/headhunting/icon_advantage_01.png" />
          <div class="content">
            <h3>链接专业猎头</h3>
            <p>
              完善简历后，系统自动为您推荐所属行业优秀猎头，您可根据情况选择授权委托多名猎头帮您找工作。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/headhunting/icon_advantage_02.png" />
          <div class="content">
            <h3>告别盲目海投</h3>
            <p>
              猎头根据您的情况及要求挑选合适的企业及岗位进行推荐，告别盲目海投，应聘成功率更高。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/headhunting/icon_advantage_03.png" />
          <div class="content">
            <h3>获取更优薪酬</h3>
            <p>资深猎头背书，帮助候选人谈薪，避免尴尬，争取更高薪水。</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/headhunting/icon_advantage_04.png" />
          <div class="content">
            <h3>免费增值服务</h3>
            <p>
              专业猎头免费为候选人提供行业分析、职业规划、简历优化、面试辅导等服务。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="headhunting-container">
      <div class="title">
        <div class="en-title">
          <img src="../assets/images/Headhunters say 2.png" />
        </div>
        <div class="cn-title">猎头如是说</div>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="item in headhuntingList"
            :key="item.id"
          >
            <div class="persion-container">
              <img class="icon-pei" src="../assets/images/icon-pei.png" />
              <div class="name-box">
                <img :src="require(`../assets/images/${item.avatar}.png`)" />
                <div>
                  <h3>{{ item.name }}</h3>
                  <p class="year">{{ item.year }}</p>
                </div>
              </div>
              <div class="desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <ad-footer style="margin-top: 130px"></ad-footer>
  </div>
</template>

<script>
import AdLoginComponent from "./login.vue";
export default {
  name: "AdHeadhuntingHomeComponent",
  components: {
    "ad-login": AdLoginComponent,
  },
  data() {
    return {
      headhuntingList: [
        {
          id: 0,
          avatar: "avatar-wu",
          name: "吴先生",
          year: "从业8年，专注互联网公司研发岗位交付",
          desc: "入驻灵鸽后认识了不少资深技术专家，其中还有一些是主动找我推荐岗位的，现在我的人才库里有3000多人，灵鸽让我获得了很多高端人脉资源。",
        },
        {
          id: 1,
          avatar: "avatar-jiang",
          name: "蒋先生",
          year: "从业3年，猎头小白",
          desc: "我刚入行业，还是个没有什么人脉资源的猎头小白，在灵鸽上我找到了自己的定位，很多入职场没几年的人也有猎头的需求，都主动来找我，在这里我结识了很多高潜力求职者，现在几乎每周都能推荐到面几人，每月都有入职和过保的候选人。",
        },
        {
          id: 2,
          avatar: "avatar-xiang",
          name: "向女士",
          year: "从业10年，专注一线城市金融岗位",
          desc: "入驻灵鸽后，结识了很多高端人才，其中还不少人基于信任推荐了他的朋友给我，不仅让我拓宽了人脉圈，而且还成了几笔大单",
        },
        {
          id: 3,
          avatar: "avatar-li",
          name: "李先生",
          year: "从业7年，SOHO猎头",
          desc: "我入驻灵鸽主要是为了拓展客户，通过灵鸽找到了新的客户，之前靠个人很难签下来的单，现在都可以直接在灵鸽上做，节约了很多公关成本。",
        },
        {
          id: 4,
          avatar: "avatar-s",
          name: "Simon",
          year: "从业12年，专注医疗大健康领域高端人才招聘",
          desc: "入驻灵鸽认证为猎头后，为我带来了300多个医疗行业的候选人，这些人才已经被我纳入人才库，现在我人才库有2000多人了。",
        },
        {
          id: 5,
          avatar: "avatar-m",
          name: "米小姐",
          year: "从业2.5年，专注在线教育行业教研岗位",
          desc: "进入这个行业不算久，也是抱着试试的心态入驻灵鸽，找候选人，结果完全超出我意料，在我纠结是否要继续做猎头时在灵鸽成了一个15W的单，给了我莫大的成就感，现在我仍继续活跃在猎头行业，前段时间升级为高级猎头顾问",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".swiper-container", {
        slidesPerView: "auto",
        slidesPerview: "auto",
        spaceBetween: 40,
        centeredSlides: true,
        loop: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        pagination: ".swiper-pagination",
        paginationClickable: true,
      });
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ad-home {
  width: 100%;
  box-sizing: border-box;
  background: #f6f9fa;

  .banner-text-container {
    max-width: 1180px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    .banner-text {
      width: 686px;
      flex: 1;
      padding-top: 226px;
      .line {
        margin: 58px 0px 60px 0px;
      }
      p {
        font-size: 26px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
    .banner-login {
      position: relative;
      padding-top: 219px;
    }
  }

  .advantage-container {
    width: 100%;
    height: 760px;
    box-sizing: border-box;
    background: #f6f9fa url("../assets/images/icon-bg1.png") no-repeat center;
    background-size: cover;
    padding-top: 100px;
    .title {
      width: 100%;
      text-align: center;
      .en-title {
        img {
          width: 173px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-family: Noto Sans SC;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .advantage-list {
      display: flex;
      align-items: flex-start;
      max-width: 1180px;
      margin: 80px auto auto auto;
      .advantage-item {
        width: 268px;
        height: 320px;
        box-sizing: border-box;
        padding: 18px;

        background: #ffffff;
        box-shadow: 0px 30px 28px 0px rgba(235, 241, 244, 0.66);
        border-radius: 20px;

        margin-left: 36px;

        &:first-child {
          margin-left: 0px;
        }

        img {
          width: 100px;
          height: 118px;
        }

        .content {
          h3 {
            font-size: 16px;
            font-family: Noto Sans SC;
            font-weight: 500;
            color: #222222;
            margin-top: 36px;
          }
          p {
            font-size: 14px;
            font-family: Noto Sans SC;
            font-weight: 400;
            color: #454545;
            line-height: 20px;
            margin-top: 16px;
          }
        }
      }
    }
  }

  .headhunting-container {
    width: 100%;
    box-sizing: border-box;
    padding-top: 100px;
    .title {
      width: 100%;
      text-align: center;
      margin-bottom: 120px;
      .en-title {
        img {
          width: 275px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-family: Noto Sans SC;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }

    .swiper-container {
      width: 100%;
      height: 536px;
      .swiper-slide {
        width: 836px !important;
        height: 400px;
        font-size: 18px;
        background: #fff;
        border-radius: 20px;
        opacity: 0.4;
        &.swiper-slide-active {
          box-shadow: 0px 30px 28px 0px rgba(235, 241, 244, 0.4);
          opacity: 1;
        }

        .persion-container {
          padding: 60px 76px 0px 76px;
          margin: 0px auto;
          position: relative;
          .icon-pei {
            position: absolute;
            right: 40px;
            top: 40px;
            z-index: 10;
            width: 36px;
            height: 34px;
          }
          .name-box {
            display: flex;
            align-items: center;
            img {
              width: 112px;
              height: 112px;
            }
            div {
              flex: 1;
              padding-left: 24px;
              height: 112px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              h3,
              p {
                width: 100%;
              }
              h3 {
                font-size: 26px;
                font-family: Noto Sans SC;
                font-weight: 600;
                color: #111111;
              }
              p {
                font-size: 18px;
                font-family: Noto Sans SC;
                font-weight: 400;
                color: #999999;
              }
            }
          }
          .desc {
            font-size: 18px;
            font-family: Noto Sans SC;
            font-weight: 400;
            color: #111111;
            line-height: 34px;
            margin-top: 60px;
          }
        }
      }
    }
  }
}
</style>