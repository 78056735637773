<template>
  <div class="ad-login">
    <img
      :src="
        require(`../assets/images/${isQr ? 'pic_account' : 'pic_Qr code'}.png`)
      "
      class="qr"
      @click="onQrHandller"
    />
    <div class="account-container" v-show="!isQr">
      <el-form ref="form" :model="params" :rules="rules" label-width="0px">
        <el-form-item prop="userPhone">
          <el-input
            v-model="params.userPhone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="userCode">
          <div class="col">
            <el-input
              v-model="params.userCode"
              placeholder="请输入手机验证码"
            ></el-input>
          </div>
          <div class="col">
            <el-button type="primary">获取验证码</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">登录/注册</el-button>
        </el-form-item>
      </el-form>
      <p class="foo">
        登录即视为您同意
        <span @click="$router.push({ path: '/tos/protocol' })">
          「网页版服务协议」
        </span>
      </p>
    </div>
    <div class="qr-container" v-show="isQr">
      <div class="download">
        <img src="../assets/images/icon-dot.png" />下载APP
      </div>
      <img class="qr-img" src="../assets/images/icon-wx1.png" />
      <p>使用灵鸽APP扫码登录</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdLoginComponent",
  data() {
    return {
      isQr: false,
      params: {
        userName: null,
        userPhone: null,
        userPwd: null,
        userCode: null,
      },
      rules: {
        userPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
          },
        ],
        userCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    onQrHandller() {
      this.isQr = !this.isQr;
    },
  },
};
</script>

<style lang="scss" scoped>
.ad-login {
  width: 340px;
  height: 362px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  .qr {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 105;
    cursor: pointer;
  }

  .account-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 103;

    /deep/ .el-form {
      width: 284px;
      margin: 0px auto;
      box-sizing: border-box;
      padding-top: 70px;
      .el-form-item {
        width: 100%;
        margin-bottom: 20px;
        &:first-child {
          .el-input {
            width: 284px;
          }
        }
        &:nth-child(2) {
          .el-form-item__content {
            display: flex;
            align-items: center;
            .col {
              &:first-child {
                flex: 1;
              }
            }
          }
          .el-input {
            width: 148px;
          }
          .el-button {
            width: 126px !important;
            padding: 14px 15px;
            font-size: 14px;
            font-weight: 400;
          }
        }
        &:last-child {
          padding-top: 50px;
        }
        .el-form-item__content {
          width: 100%;
          .el-input {
            height: 42px;
            background: rgba(255, 255, 255, 0.25);
            border-radius: 2px;
            input {
              height: 42px;
              line-height: 42px;
              background: transparent;
              border: none;
              outline: none;

              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              &::placeholder {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
          .el-button {
            width: 100%;
            padding: 14px 15px;
            font-size: 14px;
            font-weight: 400;
            border-radius: 2px;
          }
          .el-form-item__error {
            height: 17px;
            line-height: 17px;
            background: url("../assets/images/icon-error.png") no-repeat 0px
              center;
            background-size: 10px 10px;
            padding-left: 15px;
            font-size: 10px !important;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    .foo {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 21px;
      span {
        cursor: pointer;
        &:hover {
          color: #0083e6;
        }
      }
    }
  }

  .qr-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 103;
    padding: 20px;
    .download {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
    .qr-img {
      display: block;
      width: 170px;
      height: 170px;
      margin: 39px auto auto auto;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
